<template>
  <div class="index-container">
    <dataFilter ref="dataFilter" :filter="filter">
      <el-button type="primary" @click="handleQuery()">查询</el-button>
      <el-button type="warning" @click="handleExport()">导出</el-button>
    </dataFilter>
    <div class="tab-content">
      <el-table border size="mini" :data="datas" style="width: 100%">
        <el-table-column label="基本信息" show-overflow-tooltip align="center">
          <el-table-column
            prop="Check.Code"
            fixed="left"
            label="测试编号"
            :width="180"
          >
          </el-table-column>
          <el-table-column label="客户姓名" :min-width="80">
            <template slot-scope="scope">
              <div>
                {{ scope.row.Check.Member.RealName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Check.Member.Mobile"
            label="手机号"
            :width="120"
          >
          </el-table-column>
          <el-table-column prop="Check.Member.Sex" label="性别" :width="60">
          </el-table-column>
          <el-table-column label="报告日期" :min-width="120">
            <template slot-scope="scope">
              <el-tag type="success">{{
                format.dateFormat(scope.row.Check.ReportDate)
              }}</el-tag>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="得分"
          prop="Score"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column label="体脂" show-overflow-tooltip align="center">
          <el-table-column
            label="体脂判定"
            prop="TZJudge"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="体脂率"
            prop="TZRate"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="体脂"
            prop="TZ"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="左臂"
            prop="TZTopLeft"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右臂"
            prop="TZTopRight"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="躯干"
            prop="TZBody"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右腿"
            prop="TZBottomRight"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="左腿"
            prop="TZBottomLeft"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="内脏脂肪" show-overflow-tooltip align="center">
          <el-table-column
            label="内脏脂肪等级"
            prop="NZDengJi"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="内脏脂肪判定"
            prop="NZJudge"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="内脏脂肪面积"
            prop="NZArea"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="内脏脂肪量"
            prop="NZ"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="骨骼肌" show-overflow-tooltip align="center">
          <el-table-column
            label="骨骼肌判定"
            prop="GGJJudge"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="骨骼肌"
            prop="GGJ"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="左臂"
            prop="GGJTopLeft"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右臂"
            prop="GGJTopRight"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="躯干"
            prop="GGJBody"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="左腿"
            prop="GGJBottomLeft"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右腿"
            prop="GGJBottomRight"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="代谢" show-overflow-tooltip align="center">
          <el-table-column
            label="代谢判定"
            prop="DXJudge"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="基础代谢量"
            prop="DXBase"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="总能量消耗"
            prop="DX"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="体型" show-overflow-tooltip align="center">
          <el-table-column
            label="体型判定"
            prop="BodyJudge"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="去脂体重"
            prop="BodyWeightNoTZ"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="身高"
            prop="BodyHeight"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="体重"
            prop="BodyWeight"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="BMI"
            prop="BMI"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="腰臀比"
            prop="BodyYaoTunBi"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="建议" show-overflow-tooltip align="center">
          <el-table-column
            label="建议体重"
            prop="RecWeight"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="体重控制"
            prop="ConWeight"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="肌肉控制"
            prop="ConJiRou"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="脂肪控制"
            prop="ConZhiFang"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :page-sizes="pageSizeList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import dataFilter from "./components/dataFilter.vue";
import { exportData1, getPageList } from "@/api/library/checkBodyComposition.js";
export default {
  components: { dataFilter },
  data() {
    return {
      loading: false,
      total: 0,
      pageIndex: 1,
      pageSizeList: [10, 20, 30, 50, 80],
      pageSize: 10,
      datas: [],
      filter: {},
    };
  },
  methods: {
    handleExport() {
      this.loadInstance = this.$loading({
        lock: true,
        text: "正在导出...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.filter = this.$refs.dataFilter.getfilter();
      exportData1(this.filter)
        .then((res) => {
          this.loadInstance.close();
          if (res) {
            window.open(this.baseUrl + res);
          }
        })
        .catch((ex) => {
          this.msg.errorMsg("导出出错：" + ex);
          this.loadInstance.close();
        });
    },
    handleQuery() {
      let tempfilter = this.$refs.dataFilter.getfilter();
      this.filter = tempfilter;
      this.getData();
    },
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      this.loading = true;
      getPageList(this.filter)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.datas = res.Items;
            this.total = res.Total || res.Items.length;
          }
        })
        .catch((ex) => {
          this.loading = false;
          this.total = 0;
          this.datas = [];
        });
    },
    pageSizeChange(val) {
      var pageSize = val;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
  },
  mounted() {
    this.handleQuery();
  },
};
</script>
<style lang="scss" scoped>
.index-container {
  .pagination {
    margin-top: 10px;
    text-align: right;
  }
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>