var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "dataFilter",
        { ref: "dataFilter", attrs: { filter: _vm.filter } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleQuery()
                }
              }
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning" },
              on: {
                click: function($event) {
                  return _vm.handleExport()
                }
              }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-content" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", size: "mini", data: _vm.datas }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "基本信息",
                    "show-overflow-tooltip": "",
                    align: "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "Check.Code",
                      fixed: "left",
                      label: "测试编号",
                      width: 180
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户姓名", "min-width": 80 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.Check.Member.RealName) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "Check.Member.Mobile",
                      label: "手机号",
                      width: 120
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "Check.Member.Sex",
                      label: "性别",
                      width: 60
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报告日期", "min-width": 120 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.format.dateFormat(
                                    scope.row.Check.ReportDate
                                  )
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("el-table-column", {
                attrs: {
                  label: "得分",
                  prop: "Score",
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "体脂",
                    "show-overflow-tooltip": "",
                    align: "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "体脂判定",
                      prop: "TZJudge",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "体脂率",
                      prop: "TZRate",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "体脂",
                      prop: "TZ",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "左臂",
                      prop: "TZTopLeft",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "右臂",
                      prop: "TZTopRight",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "躯干",
                      prop: "TZBody",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "右腿",
                      prop: "TZBottomRight",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "左腿",
                      prop: "TZBottomLeft",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "内脏脂肪",
                    "show-overflow-tooltip": "",
                    align: "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "内脏脂肪等级",
                      prop: "NZDengJi",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "内脏脂肪判定",
                      prop: "NZJudge",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "内脏脂肪面积",
                      prop: "NZArea",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "内脏脂肪量",
                      prop: "NZ",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "骨骼肌",
                    "show-overflow-tooltip": "",
                    align: "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "骨骼肌判定",
                      prop: "GGJJudge",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "骨骼肌",
                      prop: "GGJ",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "左臂",
                      prop: "GGJTopLeft",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "右臂",
                      prop: "GGJTopRight",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "躯干",
                      prop: "GGJBody",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "左腿",
                      prop: "GGJBottomLeft",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "右腿",
                      prop: "GGJBottomRight",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "代谢",
                    "show-overflow-tooltip": "",
                    align: "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "代谢判定",
                      prop: "DXJudge",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "基础代谢量",
                      prop: "DXBase",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "总能量消耗",
                      prop: "DX",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "体型",
                    "show-overflow-tooltip": "",
                    align: "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "体型判定",
                      prop: "BodyJudge",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "去脂体重",
                      prop: "BodyWeightNoTZ",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "身高",
                      prop: "BodyHeight",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "体重",
                      prop: "BodyWeight",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "BMI",
                      prop: "BMI",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "腰臀比",
                      prop: "BodyYaoTunBi",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "建议",
                    "show-overflow-tooltip": "",
                    align: "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "建议体重",
                      prop: "RecWeight",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "体重控制",
                      prop: "ConWeight",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "肌肉控制",
                      prop: "ConJiRou",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "脂肪控制",
                      prop: "ConZhiFang",
                      "show-overflow-tooltip": "",
                      align: "center"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "pagination",
            attrs: {
              background: "",
              "page-sizes": _vm.pageSizeList,
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "current-page": _vm.pageIndex
            },
            on: {
              "size-change": _vm.pageSizeChange,
              "current-change": _vm.pageCurrentChange,
              "update:currentPage": function($event) {
                _vm.pageIndex = $event
              },
              "update:current-page": function($event) {
                _vm.pageIndex = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }