import request from '@/utils/request'


export function exportData1(data) {
  return request({
    url: 'CheckBodyComposition/exportData1',
    method: 'post',
    data
  })
}

export function getPageList(data) {
  return request({
    url: 'checkBodyComposition/getPageList',
    method: 'post',
    data
  })
}
